import React from "react";
import { Link } from "react-router-dom";
import{ Row, Col, Table } from "reactstrap";

import GENInlineImg from 'assets/img/navbar/GEN/Inline/Series-8600-8700-MPNH-Inline.png';
import GENInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-8800-MPNH-Insertion.png';

import GENRInlineImg from 'assets/img/navbar/GEN/Inline/Series-8000-8100-MPNH-Inline-Remote.png';
import GENRInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-8200-MPNH-Insertion-Remote.png';

import GENFatInlineImg from 'assets/img/navbar/GEN/Inline/Series-9700-MPNH-Inline-FAT.png';
import GENFatInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-9800-MPNH-Insertion-FAT.png';

import GENFatRInlineImg from 'assets/img/navbar/GEN/Inline/Series-9100-MPNH-Inline-Remote-FAT.png';
import GENFatRInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-9200-MPNH-Insertion-Remote-FAT.png';

import '../../MobileSideNavbar.css';
import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Gen({mobileNavbarState}){
  const product_data = [
    {
      title: 'GEN',
      link: 'gen',
      description: 'Certified for Ordinary Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENInlineImg,
      default_image: GENInlineImg,
      left_image: GENInlineImg,
      right_image: GENInsertionImg
    },
    {
      title: 'GEN R',
      link: 'gen-r',
      description: 'Transmitter Certified for Ordinary Locations with Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENRInlineImg,
      default_image: GENRInlineImg,
      left_image: GENRInlineImg,
      right_image: GENRInsertionImg
    },
    {
      title: 'GEN FAT',
      link: 'gen-fat',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Transmitter Certified for Ordinary Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '2-6"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENFatInlineImg,
      default_image: GENFatInlineImg,
      left_image: GENFatInlineImg,
      right_image: GENFatInsertionImg
    },
    {
      title: 'GEN FAT R',
      link: 'gen-fat-r',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Transmitter Certified for Ordinary Locations and Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '2-6"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENFatRInlineImg,
      default_image: GENFatRInlineImg,
      left_image: GENFatRInlineImg,
      right_image: GENFatRInsertionImg
    }
  ]

  const link_dir_inline = '/products/gen/inline/';
  const link_dir_insertion = '/products/gen/insertion/';

  return(
    <>
      <div id="gen" class="sidenav" style={{'padding-top': '50px'}}>
      <Table className="table-shopping" responsive>
        <tbody>
          { product_data.map((value) => {
            return(
              <tr>
                <td>
                  <div className="contentM text-center" style={{'width': '270px'}}>
                    <img style={{'width': '270px', 'height': 'auto'}} alt="..." src={value.image} ></img>
                    <p className="nav-product-title">{ value.title }</p>
                    <p className="nav-product-description">{ value.description }</p>
                    <Row>
                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_inline + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Inline
                      </Col>

                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_insertion + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Insertion
                      </Col>
                    </Row>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </>
  )
}

export default Gen;
